@value colors: "../../../../../components/library/style/colors.css";
@value color-error from colors;

.submit {
  margin-top: 1rem;
  width: 100%;
}

.hint {
  margin-top: 4px;
  font-size: 0.75rem;
  color: color-error;
}
