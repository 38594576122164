.icon {
  margin-right: 8px;
}

.answer {
  margin-left: 24px;
  white-space: pre-line;
}

.question {
  margin-bottom: 8px;
  text-align: left;
  align-items: center;
}

.question + .question {
  margin-top: 8px;
}
