@value colors: "../../../../../../components/library/style/colors.css";
@value color-text-subtle from colors;

.label {
  margin-top: 0;
  margin-bottom: 4px;
  color: color-text-subtle;
}

.value {
  margin: 0;
  font-weight: 500;
  font-size: 1.5rem;
}
