@value colors: "../../../../components/library/style/colors.css";
@value color-error from colors;

.navigation {
  background-color: white;
  position: sticky;
  top: -1px;
  right: 0;
  z-index: 10;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 2rem 0.5rem; /* ie11 fallback: clamp not supported */
  padding: 0.5rem clamp(3rem, 10%, calc((100% - 1440px) / 2)) 0.5rem;
  background-color: white;
}

.link-group {
  display: flex;
  flex-direction: row;
}

.phone-group {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}

.phone-group a {
  display: flex;
  align-items: center;
}

.link {
  text-transform: uppercase;
  color: black;
}

.link + .link {
  margin-left: 2rem;
}

@supports (column-gap: 2rem) {
  .link-group {
    column-gap: 2rem;
  }

  .link + .link {
    margin-left: 0;
  }
}

.link.active {
  color: color-error;
}

.phone p {
  margin: 0;
}

.phone a {
  font-weight: 500;
  font-size: 0.9rem;
  text-decoration: none;
}

.round {
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 48px;
}

.progress-container {
  opacity: 0;
  width: 100%;
  height: 2px;
  background: #ccc;
  transition: 0.3s;
}

.progress-bar {
  height: 2px;
  background: color-error;
  width: 0;
}

.active .progress-container {
  opacity: 1;
}

.hidden,
.hide-desktop {
  display: none;
}

@media screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }

  .phone-group {
    display: none;
  }

  .hide-desktop {
    display: block;
  }
}

@media screen and (max-width: 425px) {
  .content {
    padding: 0.5rem clamp(2rem, 10%, calc((100% - 1440px) / 2)) 0.5rem;
  }
}
