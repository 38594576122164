@value colors: "components/library/style/colors.css";
@value color-purple from colors;

.background {
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: color-purple;
  margin-bottom: 2rem;
}

.container {
  width: 50%;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 12px 1px #dddddd;
  border-radius: 5px;
}

.text {
  font-size: 2rem;
  text-align: center;
  white-space: pre-line;
  line-height: 150%;
}

.button {
  width: 100%;
}

@media screen and (max-width: 425px) {
  .background {
    padding: 0;
    background: none;
  }

  .container {
    width: 100%;
  }

  .text {
    font-size: 1.5rem;
  }
}
