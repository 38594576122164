@value colors: "../library/style/colors.css";
@value color-success, color-error, color-error-dark from colors;

.invest {
  z-index: 1;
  width: 100%;
  background-color: color-error;
}

.invest:hover {
  background-color: color-error-dark;
}
