@value colors: "../../../../../components/library/style/colors.css";
@value color-error from colors;

.picture {
  width: 30%;
  max-height: 400px;
  max-width: 260px;
  object-fit: cover;
  display: block;
  margin: 16px auto auto;
  border-radius: 25px 0;
}

.member {
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.member + .member {
  margin-top: 48px;
}

.name {
  font-family: Raleway, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
}

.title {
  margin: 0;
  font-style: italic;
}

.highlight {
  width: 25%;
  max-width: 150px;
  border-radius: 5px;
  margin: 0;
  border: none;
  border-top: 8px solid #ebeefd;
  position: relative;
  top: -12px;
  z-index: -1;
}

.list {
  display: flex;
  flex-direction: column;
}

.delete {
  display: flex;
  align-items: center;
  color: color-error;
  margin-top: 4px;
}

.trash {
  margin-right: 4px;
}

.editor-container {
  margin-top: 1rem;
}

.editor.preview {
  width: 100%;
}

.editor h2:first-child {
  font-size: 1.5rem;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  margin-top: 0;
  width: max-content;
}

.editor h2:first-child::after {
  content: " ";
  display: block;
  width: 5%;
  min-width: 50px;
  height: 4px;
  background-color: black;
  margin-top: 8px;
}

@media screen and (max-width: 425px) {
  .member {
    width: 100%;
    min-width: unset;
  }
}
