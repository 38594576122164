@value colors: "../../../../components/library/style/colors.css";
@value color-purple, color-success from colors;

.connected {
  padding: 0 clamp(3rem, 10%, calc((100% - 1440px) / 2)) 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  background-color: #f4f6fe;
}

.logout {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 1rem;
  padding: 0 clamp(3rem, 10%, calc((100% - 1440px) / 2)) 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.button {
  color: white !important;
  margin-left: 1rem;
}

.button:hover {
  text-decoration: underline;
}

.container {
  background-color: #f4f6fe;
}

.link {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
}

.icon {
  margin-right: 4px;
}

.description {
  text-align: center;
}

@media screen and (max-width: 425px) {
  .connected,
  .logout {
    padding: 0 clamp(2rem, 10%, calc((100% - 1440px) / 2)) 0;
  }

  .description {
    margin-bottom: 0;
  }

  .button {
    margin-left: 0;
  }
}
