@value colors: "../style/colors.css";
@value color-purple, color-purple-dark, color-gray-light, color-text-subtle, color-red, color-red-dark from colors;

.secondary {
  background-color: color-purple;
  color: white;
}

.secondary:hover {
  background-color: color-purple-dark;
  color: white;
}

.primary {
  background-color: color-red;
  color: white;
}

.primary:hover {
  background-color: color-red-dark;
  color: white;
}

.button {
  border-radius: 4px;
  min-height: 42px;
  height: 32px; /* workaround to be able to center vertically on ie11 source: https://stackoverflow.com/questions/19371626/flexbox-not-centering-vertically-in-ie/25386261#25386261 */
  padding: 0 16px;
  transition: 0.3s;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  text-align: center;
}

.button.ghost {
  background: none;
  padding: 0;
}

.button.outline {
  background: none;
  border: 1px solid;
}

.button.secondary.ghost {
  color: color-purple;
}

.button.secondary.ghost:hover {
  color: color-purple-dark;
}

.button.secondary.outline {
  color: color-purple;
}

.button.secondary.outline:hover {
  color: color-purple-dark;
}

.button[disabled],
.button[disabled]:hover {
  opacity: 0.5;
}

.button[disabled].secondary:hover {
  background-color: color-purple;
}

.button.ghost[disabled],
.button.ghost[disabled]:hover {
  color: color-text-subtle;
  background: none;
}

.button.primary.outline {
  color: color-red;
}

.button.primary.outline:hover {
  color: color-red-dark;
}
