@value colors: "../library/style/colors.css";
@value color-success, color-error from colors;

.img {
  height: 75px;
  width: max-content;
  object-fit: contain;
  margin-top: 4px;
}

.reset {
  width: max-content;
}

.loading,
.success,
.fail {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.icon {
  margin-right: 4px;
}

.success {
  color: color-success;
}

.fail {
  color: color-error;
}
