@value colors: "../../../../../components/library/style/colors.css";
@value color-text-subtle from colors;

.summary {
  width: 50%;
}

.title {
  font-size: 2.25rem;
}

.description {
  font-size: 1.25rem;
  color: color-text-subtle;
}

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid > p,
.grid > div {
  width: 50%;
  margin-bottom: 1rem;
}

@supports (display: grid) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .grid > p,
  .grid > div {
    width: auto;
  }
}

.hint {
  display: flex;
  align-items: flex-start;
  column-gap: 4px;
}

.hint,
.hint span {
  font-size: 0.75rem;
  margin: 0;
  text-transform: uppercase;
}

.hint svg {
  font-size: 1rem;
}

.info {
  margin-left: 8px;
  font-size: 16px !important;
}

.info:hover {
  cursor: pointer;
}

.favorite {
  margin-right: 0.5rem;
}

@media screen and (max-width: 768px) {
  .summary {
    width: 100%;
    margin-bottom: 24px;
  }
}
