.title {
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
}

.description {
    text-align: center;
    margin: 0.5rem 0 0;
}

.end {
    font-size: 1.5rem;
}

.end-description {
    max-width: 460px;
}
