@value colors: "../../../../../components/library/style/colors.css";
@value color-purple from colors;

.editor {
  margin-bottom: 16px;
}

.editor h2 {
  font-size: 1.25rem;
  font-weight: 700;
  color: color-purple;
  margin-top: 0;
}

.editor h2:first-child {
    font-size: 1.5rem;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
    margin-top: 0;
    width: 100%;
}

.editor h2:first-child::after {
  content: ' ';
  display: block;
  width: 5%;
  min-width: 50px;
  height: 4px;
  background-color: black;
  margin-top: 8px;
}

.editor h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: color-purple;
  margin-top: 0;
}

.editor figure {
  max-width: 900px;
  width: auto;
}

.editor img {
  display: block;
  width: auto;
  max-width: 100%;
  margin: auto;
}
