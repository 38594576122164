@value colors: "../../../../../../components/library/style/colors.css";
@value color-error, color-error-dark from colors;

.title {
  font-weight: 500;
  font-size: 1.25rem;
  margin: 0 0 24px;
}

.row {
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  margin-top: 16px;
}

.delete {
  background-color: color-error;
}

.delete:hover {
  background-color: color-error-dark;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.inputFile {
  display: flex;
  flex-direction: column;
}
