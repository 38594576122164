.title {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #000;
  margin-top: 8px;
  margin-bottom: 8px;
}

.separator {
  width: 5%;
  min-width: 50px;
  border-top: 4px solid #000;
  margin: 0 0 24px;
}

.section {
  margin-top: 48px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
