@value colors: "components/library/style/colors.css";
@value color-error from colors;

.title {
  margin: 0;
  font-size: 1.75rem;
  text-align: center;
  line-height: 150%;
}

.description {
  text-align: center;
  margin-top: 8px;
}

.button {
  margin-top: 16px;
  width: 100%;
}

.error {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  color: color-error;
}

.submitting {
  margin-left: 0.5rem;
}
