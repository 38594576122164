.social {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 16px;
}

.social a {
  color: black;
}

@media screen and (max-width: 425px) {
  .social {
    display: none;
  }
}
