@value colors: "../../../../../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-error, color-text-subtle from colors;

.title {
  text-align: center;
  font-size: 1.50rem;
  max-width: 650px;
  margin: 0 0 1rem;
  text-wrap: balance;
  line-height: 1.5;
}

.description {
  margin-top: 4px;
  text-align: center;
}

.investor-hint {
  text-align: center;
  max-width: 650px;
  color: color-text-subtle;
}

.form {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
}

.button {
  width: 100%;
  margin-top: 24px;
}

.hint {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 4px 0 0;
  color: color-error;
}

.hint span {
  font-size: 0.75rem;
}

.icon {
  font-size: 1rem !important;
  margin-right: 4px;
}

.back {
  margin: 0.5rem auto auto;
}

.submitting {
  margin-left: 0.5rem;
}

@media screen and (max-width: 450px) {
  .title {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }

  .form {
    grid-template-columns: repeat(1, 1fr);
  }
}
