@value colors: "../../../../../components/library/style/colors.css";
@value color-text-subtle, color-gray-light from colors;

.disable-scroll {
  overflow-x: hidden;
  overflow-y: hidden;
  touch-action: none;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 450px;
}

.title {
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;
  margin-top: 0;
}


.description {
  color: color-text-subtle;
  text-align: center;
  margin: 0;
}

.details {
  color: color-text-subtle;
  cursor: pointer;
  background-color: color-gray-light;
  padding: 5px;
  border-radius: 5px;
}

.button {
  margin-top: 0.75rem;
}

.whatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  background-color: #26d367;
  border-radius: 5px;
}

.whatsapp a {
  display: flex;
}

.credit-error {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  max-width: 450px;
}

.credit-error .title {
  text-align: left;
  margin: 0 0 0.25rem;
}

.credit-error .description {
  text-align: left;
  margin: 0;
}

.credit-error .whatsapp,
.credit-error button {
  width: 100%;
  margin: 0;
}

.button-group {
  width: 100%;
}

.button-group button {
  margin-top: 0.5rem;
}
