@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

html {
  font-size: 16px;
}

@media screen and (max-width: 425px) {
  html {
    font-size: 14px;
  }
}

button,
.button {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

h1,
.h1 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 2rem;
}

h2,
.h2 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}

h3,
.h3 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

h4,
.h4 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 1rem;
}

h5,
.h5 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 1rem;
}

h6,
.h6 {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 1rem;
}

a,
p,
span,
label,
.body-1,
.p {
  font-family: Roboto, sans-serif;
  font-size:1rem;
  line-height: 150%;
}

b,
.bold {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

a,
.link {
  background: none;
  border: none;
  color: #526af2;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  text-decoration: underline;
}

a:hover,
.link:hover {
  color: #404fa1;
}

.body-2 {
  font-family: Roboto, sans-serif;
  font-size: 0.8rem;
}

.caption {
  font-family: Roboto, sans-serif;
  font-size: 0.8rem;
}

.subtitle-1 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 130%;
  text-transform: uppercase;
}

.subtitle-2 {
  font-family: Raleway, sans-serif;
  font-size: 1rem;
}
