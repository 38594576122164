.iti,
.iti input {
  width: 100%;
}

.iti input {
  min-height: 32px;
  padding: 8px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  transition: 0.3s;
}

.iti input:focus {
  border-color: #4f67f3;
}

.error .iti input {
  border-color: #de330d;
}

.iti--separate-dial-code,
.iti__selected-flag {
  background-color: #e6e6e6 !important;
  font-family: Roboto, sans-serif;
}
