.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: white;
  max-width: 750px;
  max-height: 90vh;
  padding: 2rem;
  border-radius: 5px;
  margin: 2rem;
}

.scroll {
  overflow-y: scroll;
}

.modal::-webkit-scrollbar {
  width: 5px;  /* for vertical scrollbars */
}

.modal::-webkit-scrollbar-thumb {
  background: rgb(0 0 0 / 10%);
  border-radius: 5px;
}

@media screen and (max-width: 425px) {
  .modal {
    width: 100%;
    max-height: 100vh;
    overflow: scroll;
    min-width: unset;
    box-sizing: border-box;
    margin: 0;
  }
}
