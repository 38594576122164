body {
  margin: 0;
}

button {
  background: none;
  outline: none;
  margin: 0;
  border: none;
  cursor: pointer;
  color: inherit;
}

a {
  color: black;
  text-decoration: underline;
}

img {
  max-width: 100%;
}

.ck {
  border: 1px solid #e6e6e6 !important;
  border-radius: 5px !important;
  margin-bottom: 8px;
}

.ck:hover,
.ck:focus {
  border-color: #4F67F3 !important;
}

.image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

.image-style-side img {
  width: 100%;
}

.disable-scroll {
  height: 100vh;
  overflow: hidden;
  touch-action: none;
}
