@value colors: "../../../../../components/library/style/colors.css";
@value color-text-subtle from colors;

.input {
  outline: none;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  min-height: 32px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.input:focus {
  border-color: #526af2;
}

.label {
  margin-top: 16px;
}

.title {
  font-size: 1.5rem;
  margin: 0;
}

.save {
  margin-top: 16px;
  margin-left: auto;
}

.hint {
  margin: 0 0 4px;
  font-size: 0.80rem;
  color: color-text-subtle;
}

.img {
  display: flex;
  flex-direction: column;
}

.rowFile {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
}
