@value colors: "../../../../components/library/style/colors.css";
@value color-error from colors;

.recommendation {
  padding: 0.5rem 0 2rem;
}

.title {
  font-weight: 500;
  font-size: 1.25rem;
  margin-top: 0;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.file {
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  margin-top: 24px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  border-left: 8px solid color-error;
  border-radius: 5px;
  box-shadow: 0 0 15px #dddddd;
}

.picture {
  width: 96px;
  height: 96px;
  border-radius: 96px;
  object-fit: cover;
  margin-right: 1.5rem;
}

.name {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 4px;
}

.jobTitle {
  margin-top: 0;
  font-style: italic;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 48px;
  margin-bottom: 24px;
}

.delete {
  color: color-error;
  margin-left: auto;
  margin-right: 0;
}

.content {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .list {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
  }
}
