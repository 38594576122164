.form {
  padding: 1rem 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.option {
  margin-right: 0.5rem;
}
