.container {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    width: 100%;
    max-width: 245px;
    margin-right: 0;
}

.text {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.title,
.description {
    margin: 0;
}

.title {
    font-size: 1.25rem;
    font-family: Roboto, sans-serif;
    line-height: 150%;
}

.description {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
    line-height: 150%;
}
